.popup-release {
  position: absolute;
  .popup-container {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    z-index: 11;
    width: 100%;
    margin: 120px 8%;
    padding: 100px;
    .header {
      font-size: 38px;
    }
    .beta-warning {
      font-size: 20px;
      text-align: center;
      margin-top: 20px;
    }
  }
  @media (max-width: 800px) {
    .popup-container {
      margin: 80px 20px;
      padding: 30px 40px 30px;
    }
  }
}
