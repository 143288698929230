@import "../../variables.scss";

.header-container {
  --header-color-bg: white;
  --header-color-text: black;
}


.header-container.black-header {
  --header-color-bg: black;
  --header-color-text: white;
}

.header-container {


  position: fixed;
  background: var(--header-color-bg);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  //gap: 30px;
  //padding: 30px 0;
  color: var(--header-color-text);
  font-size: 20px;
  .container {
    &-top {
      max-width: min(95vw, 1800px);
      @media (max-width: 800px) {
        padding: 0;
        width: 100%;
      }
      padding: 15px 0;
      width:  80%;
      //background: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        width: 100%;
        //gap: 20px;
        @media (max-width: 800px) {
          max-width: 100%;
          padding-right: 10px;
        }
        display: flex;
        justify-content: space-between;
        color: #ae9151;
        .left {
          display: flex;
          align-items: center;
          div {
            transition: transform 0.2s ease-in-out;
            margin-right: 5px;
            cursor: pointer;
            &:hover {
              color: var(--header-color-text);
              transform: scale(1.01);
            }
          }
        }
      }
      .right {
        .webon-store {
          text-decoration: none;
          margin-right: 20px;
          color: black;
        }
        .plugin-button {
          text-decoration: none;
          margin-right: 30px;
          color: black;
        }
        .active{
          color: black;
        }
        display: flex;
        align-items: center;
        div {
          cursor: pointer;
          &:hover {
            color: black;
          }
        }
      }
      .active.button-outline{
        border: 2px black solid;
      }
      .button-outline {
        white-space: nowrap;
        padding: 10px 40px;
        border-radius: 1000px;
        border: 2px #ae9151 solid;
        transition: 0.3s ease-in-out all;
        &:hover {
          background: black;
          cursor: pointer;
          color: #ffffff !important;
          border: 2px black solid;
        }
      }
    }
  }
}
.separator {
  width: 100%;
  height: 1px;
  background: rgba(grey, 0.7);
}
.header-mobile-container {
  //isolation: isolate;
  z-index: 10;
  position: fixed;
  width: 100%;
  background: white;
  @media (min-width: 800px) {
    display: none;
  }
  font-size: var(--medium-size);
  .container-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 3%;
    .sandwichbutton {
      cursor: pointer;
    }
  }
  .container-bottom {
    display: flex;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    .left {
      padding-top: 35px;
      height: 300px;
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-left: 30px;
      gap: 15px;
      div {
        width: fit-content;
        cursor: pointer;
        &:hover {
          color: #ae9151;
        }
      }
      .active{
        color: #ae9151;
        box-shadow: 0 0 0 5px white, 0 0 0 6px #ae9151;
      }
    }
    .right {
      padding-top: 35px;
      .icons {
        display: flex;
        gap: 5%;
      }
      height: 300px;
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-right: 30px;
      gap: 15px;
      color: #ae9151;
      > div:not(.icons) {
        width: fit-content;
        cursor: pointer;
        &:hover {
          color: black;
        }
      }
      .active{
        color: black;
        box-shadow: 0 0 0 5px white, 0 0 0 6px black;
      }
    }
  }
  .mobile-active {
    max-height: 300px;
  }
  .close-mobile {
    z-index: -1;
    position: fixed;
    inset: 0 0 0 0;
    //background: green;
  }
}

.ZENIQ-logo {
  @media(max-width: 800px) {
    display: none;
  }
}
.ZENIQ-logo-short {
  margin: 10px;
  display: none;
  @media(max-width: 800px) {
    display: block;
  }
}
/* Responsive Styles for 425px and Below */

@media (max-width: 425px) {
  .header-container .container-top {
    padding: 5px 0;

    .left div,
    .right .plugin-button,
    .right .button-outline {
      font-size: 12px;
      margin: 5px ;
      margin-right: 15px;

    }

    .button-outline {
      padding:5px 8px;
    }
  }

  .ZENIQ-logo-short {
    margin: 5px;

  }
}
