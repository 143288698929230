/* Reset Styles */
body, html, .container {
  margin: 0;
  padding: 0;
}

/* Variables */
:root {
  --gray-dark: #e8e8e8;
  --gray-light: #f9f9f9;
  --gold: #bba46f;
  --white: #ffffff;
  --black: #000000;
}

/* Menu Styles */
.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--gray-dark);
  height: 100vh;
  width: 250px;
  gap: 32px;
  padding: 100px 0 10px 0;

}

.menu-item {
  font-size: 22px;
  font-weight: 400;
  padding: 12px 0 0 20px;
  cursor: pointer;



  svg {
    width: 20px;
    height: 20px;
    margin-left: 100px;

  }

}

/* Responsive styles */

@media (min-width: 320px) and (max-width: 767px) {
  .menu {
    align-items: center;
    justify-content: center;
    height: auto;
    background:  white;
    padding-top: 1vw;
    padding-left: 3vw;
  }

  .menu-item {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    background: var(--white);
    color: var(--gold);
    border-radius: 30px;
    border: 1px solid currentColor;
    cursor: pointer;
    transition: background 0.5s ease;
    padding: 15px 70px;

    svg {
      display: none;
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .menu {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: auto;
    background:  white;
    padding-top: 1vw;
    padding-left: 3vw;

  }

  .menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 70px;
    font-size: 19px;
    background: var(--white);
    color: var(--gold);
    border-radius: 30px;
    border: 1px solid currentColor;
    cursor: pointer;
    transition: background 0.5s ease;

    svg {
      display: none;
    }
  }
}

@media (min-width: 1024px) and (max-width: 2559px) {
  .menu {
    width: 200px;
    position: absolute;
    left: 0;
   top: 0;

  }

  .menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

      .icon-right{
       margin-left: 60px;
      }

  }

  .menu-help {
    margin-top: auto;
    padding-bottom: 25%;
  }
}

@media (min-width: 2560px) {
  .menu {
    position: absolute;
    left: 0;
    top: 0;
    width: 430px;

  }
  .menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      margin-left: 12vw;
    }
    .icon-right{
      margin-left: 10.4vw;
    }

  }


  .menu-help {
       margin-top: auto;
       padding-bottom: 25%;
     }
}