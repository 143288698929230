#__next {
  --header-size: 38px;
  --medium-size: 22px;
  --small-size: 18px;
  --background-color: black;
  --text-color: #ffffff;
  --hover-color: white;

  @media (max-width: 800px) {
    --header-size: 30px;
    --medium-size: 18px;
    --small-size: 14px;
  }
}

.dark {
  --background-color: black;
  --text-color: #ffffff;
  --hover-color: white;
  --chatbanner-bg: white;
}


.light {
  --background-color: #ffffff;
  --text-color: #000000;
  --hover-color: white;
  --chatbanner-bg: black;
}