
.access_page{
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  color: #333;
  font-size: 1.2em;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #cdaf6d;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.content-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
}

.box {
  flex: 1 1 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  min-width: 250px;
  max-width: 400px;
  height: 150px;
  position: relative;
  overflow: hidden;
}

.box:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  z-index: 1;
  transition: transform 0.5s;
}

.box:hover:before {
  transform: scale(1.1);
}

.box:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: opacity 0.5s;
}

.box:hover:after {
  opacity: 1;
}

.box.installation {
  background-color: #cdaf6d;
}

.box.webon {
  background-color: #656565;
}

.box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.box a {
  text-decoration: none;
  color: #f8f8f8;
  font-weight: bold;
  font-size: 1.5em;
  position: relative;
  z-index: 2;
  text-align: center;
}

.box a:hover {
  text-decoration: underline;
}

h2 {
  text-align: center;
  margin-bottom: 40px;
  font-weight: bold;
  color: #333;
  letter-spacing: 2px;
  position: relative;
}