.download-page {
  background: #fafafa;
  isolation: isolate;
  max-width: 100%;


  .header {
    font-family: Oxanium, serif;
    font-size: 38px;
    margin-bottom: 20px;
  }
  .header-small {
    font-family: Oxanium, serif;
    font-size: 26px;
    margin-top: 20px;
  }
  .beta-warning {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header {
      margin: 0 20px 0 0;
    }
    .button {
      font-weight: bolder;
      width: 270px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      //color: black;
      border: black 2px solid;
      font-size: 1.5rem;
      padding: 8px 36.5px;
      border-radius: 10px;
      transition: 0.2s all ease-in-out;
      white-space: nowrap;
      color: black;
      background: white;
      &:hover {
        cursor: pointer;
        color: white;
        background: black;
      }
    }
    @media (max-width: 700px) {
      flex-direction: column;
      justify-content: center;
      gap: 20px;
    }
  }
  .checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    .red {
      color: red;
    }
  }
  .instructions {
    display: flex;
    flex-direction: column;
    vertical-align: center;
    gap: 20px;
    margin-block: 20px;
  }

  .button-outline {
    font-weight: bolder;
    width: 270px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    //color: black;
    font-size: 1.5rem;
    padding: 8px 36.5px;
    border-radius: 10px;
    transition: 0.2s all ease-in-out;
    white-space: nowrap;
    color: white;
    background: black;
    &:hover {
      border: 2px black solid;
      background: white;
      cursor: pointer;
      color: black;
    }
  }
  .downloads-container {
    position: relative;
    margin: 40px 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: space-evenly;
    .row {
      backdrop-filter: blur(2px);
      background-color: rgba(white, 0.8);
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: 40px;
      min-height: 200px;
      width: 100%;
      border: 2px black solid;
      border-radius: 15px;
      justify-content: flex-end;
      padding: 20px 50px;
      flex-direction: row;
      flex-wrap: wrap;
      transition: 0.2s ease-in-out;
      &:hover {
        //background-color: white;
      }
      @media (max-width: 700px) {
        flex-direction: column;
        justify-content: center;
      }
      @media (max-width: 700px) {
        padding: 20px 5px;
      }
      .image-container {
        flex-grow: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      > * {
        flex-grow: 1;
      }
      .buttons {
        width: unset;
        display: flex;
        @media (max-width: 700px) {
          flex-direction: column;
          justify-content: center;
        }
      }
      img {
        max-width: 120px;
        min-width: 120px;
        transform: scale(1);
      }
      .buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: flex-end;
        align-items: center;
        .percentage {
          font-family: Oxanium, serif;
          font-size: 30px;
          margin-right: 10px;
          min-height: 38px;
        }
      }

      .button-outline {
        font-weight: bolder;
        width: 270px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        //color: black;
        font-size: 1.5rem;
        padding: 8px 36.5px;
        border-radius: 10px;
        transition: 0.2s all ease-in-out;
        white-space: nowrap;
        color: white;
        background: black;
        &:hover {
          border: 2px black solid;
          background: white;
          cursor: pointer;
          color: black;
        }
      }
      &:hover {
        .image-container {
          img {
            transition: 0.2s ease-in-out all;
            transform: scale(1.05);
          }
        }
      }
    }
  }

  .percentage {
    min-width: 80px;
    font-family: Oxanium, serif;
    font-size: 30px;
    margin-right: 10px;
  }
  .button-container-linux {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }
  .button-container-mac {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    .percentage {
      min-height: 40px;
      text-align: center;
    }
  }
  .mobile-banner {
    border-radius: 15px;
    background: url('../../assets/downloads_mobile.png') no-repeat center !important;
    background-size:  min(100vw, 1500px);
    position:absolute;
    left:0;
    right:0;
    top: 0;
    bottom: 0;
    z-index: -2;
  }
  .mobile {
    justify-content: space-between;
    .row {
      min-height: 200px;
      //width: max(300px, 30%);
      border: 2px black solid;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      //padding: 20px 50px;
      //flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      > * {
        flex-grow: 1;
      }
      .buttons {
        width: unset;
      }
    }
  }
  .desktop {
    .mobile-banner {
      margin: 40px;
      background: url('../../assets/desktop.png') center center !important;
      background-color: #fafafa !important;
      background-repeat: no-repeat !important;
      background-size:  contain !important;
      z-index: -1;
      @media (max-width: 800px) {
        background-repeat: space !important;
        background-position: center 400px !important;
        margin-top: 20px;

      }
    }
  }
}

.popup {
  overflow: hidden;
  color: black !important;
  position: fixed;
  inset: 0 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  .background {
    position: absolute;
    inset: 0 0 0 0;
    background: #000000;
    opacity: 0.2;
  }
  .popup-container {
    position: relative;
    min-height: 300px;
    max-height: 70vh;
    min-width: 320px;
    max-width: 100%;
    background: white;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 2px black solid;
    padding: 80px;
    border-radius: 15px;
    overflow-x: hidden;
    overflow-y: auto;
    > * {
      max-width: 100%;
    }
    @media (max-width: 800px) {
      padding: 10px;
      padding-top: 50px;
    }
    .header {
      margin-block: 10px;
    }
    img {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
      height: 30px;
      width: 30px;
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 90%;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    height: 90%;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  ::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 10px;
  }

  ::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 10px;
  }
}
.center-text {
  text-align: center;
}