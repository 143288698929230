/* Base styles for all screen sizes */
.top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8rem 5vw;
}

.headline-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  font-family: "Oxanium", serif;
}

.headline {
  font-size: clamp(1rem, 1vw, 2rem);
  color: #cdaf6d;
  text-align: center;

}
.sub-headline {
  font-size: clamp(2rem, 2vw, 3rem);
  color: #ffffff;
  text-align: center;
  padding: 1rem 0;
}

.start-link {
  padding: 1rem 1rem;
  border-radius: 1000px;
  border: 2px white solid;
  background: #cbad69;
  color: white;
  text-decoration: none;
  text-align: center;
  transition: 0.2s all ease-in-out;
  white-space: nowrap;
  margin-bottom: 1rem;
  font-size: clamp(1rem, 1vw, 2rem);

}

.start-link:hover {
  background: #ffffff;
  color: black;
}


.top-img {
  width: 22vw;
  max-width: 120px;
  margin-bottom: 1rem;
  padding-top: 2rem;
}

.previewed {
  width: 90vw;
  max-width: 600px;
}

@media (min-width: 769px) {
  .top-container {
    flex-direction: row;
    justify-content: center;
    padding-top: clamp(5vw, 12vw, 8vw);
  }

  .headline-container {
    padding-top: 4vw;
    margin-right: 2rem;
    flex: 1;
    max-width: 50%;
    align-items: flex-start;
  }
  .headline{
    text-align: left;
  }
 .sub-headline{
  text-align: left ;
   padding-bottom: 2vw;
}
  .previewed {
    width: calc(100% - 40vw);
    max-width: 900px;
    order: 2;
  }
}

/* Styles for screens smaller than 769px */
@media (max-width: 769px) {
  .top-container {
    flex-direction: column;
    align-items: center;
  }

.headline, .sub-headline{
  text-align: left;
}

  .top-img {
    width: 40vw;
  }
  .previewed {
    width: 90vw;
    order: 3;
  }

}
#herobanner {
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 40px;
  @media (max-width: 800px) {
    padding-top: 10px;
  }
  .text-container {
    font-family: "Oxanium", serif;
    overflow: hidden;
    width: 700px;
    font-weight: bold;
    bottom: 15%;
    left: 15%;
    @media (min-width: 1800px) {
      left: 25%;
    }
    @media (max-width: 800px) {
      transform-origin: bottom left;
      bottom: 10px;
      left: 10px;
      transform: scale(0.8);
    }
    height: 80px;
    position: absolute;
    font-size: 60px;
    sub {
      font-size: 20px;
    }
    div {
      position: absolute;
      height: 80px;
      animation: text-swap 8s linear infinite reverse;
      animation-delay: calc(var(--i) * -2s);
    }
    div:nth-child(5) {
      color: #d8bc8a;
    }
    div:nth-child(2) {
      visibility: hidden;
    }
    @media (max-width: 800px) {

      div {
        font-size: 45px;
      }
      div:nth-child(2) {
        visibility: visible;
      }
      div:nth-child(1) {
        visibility: hidden;
      }
    }
  }
  video {
    width: 90%;
    max-width: 1500px;
    @media (min-width: 1800px) {
      max-width: 80%;
    }
  }
}

@keyframes text-swap {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-100%);
  }
  70% {
    transform: translateY(-100%);
    opacity: 1;
  }
  71% {
    transform: translateY(-100%);
    opacity: 0;
  }
  84% {
    transform: translateY(100%);
    opacity: 0;
  }
  88% {
    transform: translateY(100%);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
  }
}


.copied-feedback {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  color: #000000;
  padding: 0.5em;
  border-radius: 0.3em;
  font-size: 1em;
  animation: fadeOut 2s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
