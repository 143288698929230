.app-container {
  color: black;
  background: white;
  isolation: isolate;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 40px;
  .left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-width: 45%;
    width: 400px;
    gap: 16px;
    flex-grow: 1;
    .header {
      font-size: var(--header-size);
    }
    .information {
      font-size: var(--small-size);
    }
    .bold {
      font-size: var(--medium-size);
      font-weight: bolder;
    }
    .checklist-container {
      display: flex;
      flex-direction: column;
      gap: 16px;

      font-size: var(--small-size);
      div {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }
    .button-container {
      z-index: 2;
      margin-top: 10px;
      width: 120%;
      font-size: 25px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 20px;
      @media (max-width: 800px) {
        width: 100%;
        justify-content: center;
        .button {
          flex-grow: 1;
        }
      }
      .button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        &:hover {
          //transform: translateY(-1px);
        }
      }
    }
  }
  .right {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    @media (max-width: 1600px) {
      height: 600px;
    }
    @media (max-width: 800px) {
      height: 400px;
    }
  }
}

.slider {
  height: 930px;
  width: 500px;
  flex-shrink: 0;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 100px;
  flex-direction: column;
  @media (max-width: 1600px) {
    //height: 600px;
    transform-origin: top left;
    transform: scale(0.7);
  }
  @media (max-width: 800px) {
    transform-origin: top left;
    transform: scale(0.5);
  }
  .appscreen {
    transform: scale(1.3);
    position: absolute;
    height: 850px;
    width: 380px;
    transition: all ease-in-out 0.5s;
    left: 900px;
    opacity: 0;
  }
  .appscreen:nth-child(1) {
    z-index: 1;
    background: url("../../assets/app/1.png") center;
    background-size: cover;
  }
  .appscreen:nth-child(2) {
    z-index: 2;
    background: url("../../assets/app/2.png") center;
    background-size: cover;
  }
  .appscreen:nth-child(3) {
    z-index: 3;
    background: url("../../assets/app/3.png") center;
    background-size: cover;
  }
  .appscreen:nth-child(4) {
    z-index: 4;
    background: url("../../assets/app/4.png") center;
    background-size: cover;
  }
  .active {
    left: 0;
    opacity: 1;
  }
  .back {
    opacity: 0;
    transform: scale(0.5);
    left: 0;
    z-index: -1 !important;
  }
  .front {
    left: 420px;
    opacity: 1;
  }
  .button {
    position: absolute;
    z-index: 20;
    cursor: pointer;
    border-radius: 1000px;
    height: 60px;
    min-width: 60px;
    bottom: 50px;
    background: #ae9151 url('../../assets/arrow.svg') no-repeat 8px;
    background-size: 40px;
    transition: all 0.4s ease-in-out;
    &:hover {
      min-width: 80px;
    }
  }
  .button:nth-child(5) {
    right: 340px;
  }
  .button:nth-child(6) {
    left: 220px;
    transform: scale(-1);
  }
  .shadow {
    height: 1000px;
    width: 2000px;
    position: absolute;
    background: linear-gradient(90deg, transparent 0%, var(--background-color) 15%, var(--background-color) 100%);
    left: 420px;
    top: -100px;
    z-index: 5;
    filter: blur(20px);
  }
  .disabled {
    background: #3a3a3a;
  }
}

#appBanner {
  background: white;
}