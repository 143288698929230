.content {
    flex-grow: 1;
    width: 100%;
    background: var(--background-color);
    padding-top: 5px;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.transparent {
    background: transparent !important;
}