.footer {
  background-color: #1b1b1b;
  padding: 20px 0;
  text-align: center;
  font-size: 18px;


  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    gap: 8px;

    p {
      margin: 0;
      color: #f4f4f3;
    }

    a {
      color: #bba46f; // Accent color
      font-weight: 500;
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
        color: #e0c488; // Brighter accent on hover
        text-decoration: underline;
      }
    }
  }

  .footer-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 8px;

    a {
      color: #cdcdcc;
      font-size: 16px;

      &:hover {
        color: #e0c488;
      }
    }
  }

  @media (max-width: 768px) {
    .footer-content {
      text-align: center;
      gap: 10px;
    }

    .footer-links {
      flex-direction: column;
      gap: 10px;
    }
  }
}
