.error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.error-content {
  text-align: center;
  max-width: 400px;
}

.error-icon {
  color: #2c3e50;
  margin-bottom: 20px;
  font-size: 4rem;
}

h1 {
  color: #2c3e50;
  font-size: 2.25rem;
  margin-bottom: 0.5rem;
}

p {
  color: #6c757d;
  margin-bottom: 1.25rem;
}

.error-button {
  display: inline-block;
  text-decoration: none;
  background-color: #ccaf73;
  color: white;
  padding: 0.625rem 1.25rem;
  border-radius: 0.3125rem;
  transition: background-color 0.3s;
  &:hover {
    background-color: #7c6945;
  }
}