@import "../../variables.scss";

.banner {
  padding-block: 250px;
  display: flex;
  flex-direction: column;
  isolation: isolate;
  color: var(--text-color);
  @media(max-width: 800px) {
    padding-block: 0;
  }
  .banner-information-container {
    max-width: 95vw;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    @media(max-width: 800px) {
      max-width: 95vw;
      padding-block: 150px;
    }
    @media(max-width: 1800px) {
      flex-wrap: wrap;
    }
    @media(max-width: 1800px) {
      flex-wrap: wrap;
    }
    .left {
      padding: 0 10px;
      flex-grow: 1;
      display: flex;
      gap: 20px;
      flex-direction: column;
      width: 48%;
      //min-width: 330px;
      justify-content: center;
      .header {
        font-size: var(--header-size);
      }
      .information {
        font-size: var(--small-size);
      }
      .bold {
        font-size: var(--medium-size);
        font-weight: bolder;
      }
    }
    .right {
      position: relative;
      width: 45%;
      min-width: min(600px, 100%);
      max-width: clamp(800px, 100%, 900px);
      display: flex;
      justify-content: center;
      flex-grow: 1;
      align-items: center;
      //overflow: hidden;
      z-index: -1;
      img {
        border: 1px solid black;
        border-radius: 3px;
        min-width: min(600px, 100%);
      }
    }
  }

  .button-outline {
    width: 380px;
    margin: 10px 0;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    //color: black;
    font-size: 1.375rem;
    padding: 8px 36.5px;
    border-radius: 1000px;
    border: 2px white solid;
    transition: 0.2s all ease-in-out;
    white-space: nowrap;
    background: #cbad69;
    margin-bottom: 60px;
    &:hover {
      background: var(--text-color);
      cursor: pointer;
      color: var(--background-color);
    }
    @media(max-width: 800px) {
      width: 300px;
      font-size: 18px;
    }
  }
}
