.info_container {
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
}
.webon-info {
  text-align: center;
  margin-bottom: 20px;
}
.webon-details {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.webon-icon {
  width: 60px;
  height: 60px;
  border-radius: 8px;
}
.webon-name {
  font-weight: bold;
  font-size: 1.5em;
  color: #333;
}