:root {
  --img-grp-size: calc(100vw / 5);
  --qr-code-size: calc(var(--img-grp-size) * 0.8);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.download-page{
  background-color: white;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 5vw 20px 0;
}

.left-icon-container {
  position: fixed;
  top: 120px;
  left: 300px;
  z-index: 1000;

}

.left-icon {
  width: 20px;
}

.image-text {
  text-align: center;
  padding: 5px 0 10px;
  font-size: calc(12px + 0.5vw);
}

.img-grp {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 35px;
  padding-bottom: 1vw;
  padding-top: 6vw;

  img {
    width: 100%;
    max-width: 450px;
    margin-top: 80px;
    border-radius: 15px;
  }
}

.img-third {
  margin-top: 90px;
  .image-link {
    pointer-events: none;
  }
}

.qr-code-border {
  border: 2px solid #000;
  display: inline-block;
  padding: 20px;
}

.qr-code {
  transform: scale(1);
}

.copy-icon-container {
  border: 2px solid black;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  box-sizing: content-box;
}

.fa-copy {
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #bba46f;
  }
}

.text-section {
  background: linear-gradient(to right, rgba(245, 245, 245, 0.9), rgba(220, 220, 220, 0.9));
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px 25px;
  max-width: 90%;
  margin: 20px 0 20px 20px;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-5px);
  }

  h3 {
    margin-bottom: 10px;
    color: #333;

  @media (max-width: 320px) {

      overflow-wrap: break-word;
      word-wrap: break-word;
      max-width: 8ch;

  }
  }

  p {
    line-height: 1.6;
  }
}

.header-section {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 22px;
  font-weight: 500;

  .icon-img {
    border-radius: 25%;
    width: 80px;
    padding: 10px;
  }
}

.temp {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 70%;
  text-align: center;
  gap: 20px;
  margin: 20px 0;
}

@media (max-width: 768px) {
  .left-icon-container {
    top: 100px;
    left: 35px;
  }
  .img-third .image-link {
    pointer-events: auto;
  }

  .img-third {
    display: none;
  }
}

@media (min-width: 769px) {
  .container {
    padding-left: calc(6px + 12%);
    padding-top: 1vw;
    align-items: center;
    justify-content: center;
  }
  .left-icon-container {
    top: 100px;
    left: 45px;
  }

  .img-grp img {
    max-width: calc(290px + 12vw);
  }

  .img-third {
    padding-left: 3vw;
  }

  .copy-icon-container {
    display: none;
  }

  .temp {
    display: none;
  }

  .text-section {
    margin: 20px 0 20px 8px;
  }
}