.chat {
  background: var(--background-color);
  .left {
    order: 1;
    text-align: right;
  }
  .right {
    max-width: min(1000px, 100%);
    img {
      //max-width: 600px;
      min-width: min(1200px, 100%) !important;
      border: none !important;
    }
    @media(max-width: 1800px) {
      order: 2;
    }
  }
}